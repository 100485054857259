@tailwind base;
@tailwind components;
@tailwind utilities;

/* input {
  color: red;
}
.nav {
  font-family: "Product Sans", Arial, sans-serif;
  border-bottom: 2px #a9a9a9 solid;
  display: flex;
  color: #30393f;
} */
textarea {
  resize: none;
}
input,
textarea {
  width: 30%;
  padding: 20px;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.15),
    0 2px 6px 2px rgba(60, 64, 67, 0.2);
}
ul {
  list-style: none;
  margin: 30px;
}
li {
  padding: 20px;
  white-space: pre-wrap;
  word-wrap: break-word;
}
li:hover {
  box-shadow: 0 1px 2px 0 gray, 0 2px 6px 2px gray;
}
/* .modal {
  font-size: 12px;
}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.modal > .content {
  width: 100%;
  padding: 10px 5px;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
} */
